import React from 'react'
import {map} from 'lodash';

export default function Prices({prices}) {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-extrabold text-gray-900 text-center">Spetterende kortingen!</h2>
                <div className="mt-12">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
                        {map(prices, (price) => (
                            <div className="flex" key={price.id}>
                                <div className='mr-4'><img src={price.imageUrl}  style={{width: 100}}/></div>

                                <div className="justify-center align-middle">
                                    <dt className="text-lg leading-6 font-medium text-gray-900">{price.description}</dt>
                                    <dd className="mt-2 text-base text-gray-500">{price.additionalRules}</dd>
                                </div>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
