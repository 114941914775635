import React, {useState} from 'react'

const CHECK_CODE_MUTATION = `
    mutation PriceCheck($data: PriceCheckInputType!) {
        priceCheck(data: $data) {          
          ok   
           price {      
              type {
                description
              }
           } 
           claimId
           resultMessage
        }
    }`;

const PriceCheck = ({onCompleted}) => {
    const [code, setCode] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined)
    const [validationError, setValidationError] = useState(undefined)

    const validation = (code) => {
        let error = undefined

        if (code.trim(' ') === "") {
            setValidationError('Ongeldige wedstrijdcode')
        }

        return error === undefined ? true : false
    }

    const handleCheckCode = (code) => {
        setError(undefined)
        if (validation(code)) {
            setLoading(true);
            setError(undefined);

            fetch('/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: CHECK_CODE_MUTATION,
                    variables: {
                        data: {
                            code: code,
                        }
                    }
                })
            })
                .then((result) => result.json())
                .then((result) => {
                    if (result.data.priceCheck.ok) {
                        onCompleted(code, result.data.priceCheck.price, result.data.priceCheck.claimId);
                    } else if (!result.data.priceCheck.ok) {
                        const messageCode = result.data.priceCheck.resultMessage;
                        if (messageCode === "INVALID_CODE") {
                            setError("Ongeldige wedstrijdcode")
                        } else if (messageCode === "ALREADY_USED") {
                            setError("Wedstrijdcode reeds gebruikt")
                        } else {
                            setError('Er ging iets fout, probeer later opnieuw');
                        }
                    } else {
                        setError('Er ging iets fout, probeer later opnieuw');
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setError('ERROR');
                    setLoading(false);
                });
        }
    };

    return (
        <div className="bg-primary">
            <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                    <span className="block">WIN WIN WIN!</span>
                    <span className="block"></span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-indigo-200">
                    Voer hieronder uw unieke code in en bekijk onmiddellijk welke korting u heeft gewonnen.
                </p>

                <div className="mt-4">
                    <div className="flex flex-col">
                        <div className="mb-2">
                            <label htmlFor="email-address" className="sr-only"> Code </label>
                            <input
                                id="code"
                                name="code"
                                type="text"
                                required
                                className="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white rounded-md"
                                placeholder="Code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </div>

                        {validationError &&
                        <div className="bg-red-500 text-white p-2 mb-4">{validationError}</div>}

                        {error &&
                        <div className="bg-red-500 text-white p-2 mb-4">{error}</div>}

                        <button onClick={() => handleCheckCode(code)}
                                className="mt-3 w-full flex items-center justify-center py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white sm:mt-0 sm:flex-shrink-0"
                        >
                            Valideer
                        </button>
                    </div>

                    <p className="mt-3 text-sm text-indigo-100">
                        Wij geven om de bescherming van uw gegevens. Lees ons{' '}
                        <a href="/privacy.pdf" target="_blank" className="text-white font-medium underline">
                            privacybeleid.
                        </a>
                    </p>
                    <p className="mt-3 text-sm text-indigo-100">
                        Door deel te nemen aan de actie gaat u akkoord met de <a className="text-white font-medium underline" href="/actievoorwaarden.pdf" target="_blank">voorwaarden</a>
                    </p>

                </div>
            </div>
        </div>
    );
};

export default PriceCheck;
