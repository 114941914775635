import React, {useState} from 'react'
import PriceCheck from "./PriceCheck";
import PriceConfirmation from "./PriceConfirmation";

export default function Game() {
    const [data, setData] = useState(undefined);

    if (data !== undefined) {
        return (
            <PriceConfirmation data={data}/>
        )
    }

    return (
        <PriceCheck onCompleted={(code, price, claimId) => setData({
            price: price,
            code: code,
            claimId: claimId
        })}/>
    )
}

