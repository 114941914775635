import React from 'react';
import Footer from "./components/Footer";
import Header from "./components/Header";
import Game from "./components/Game";
import Prices from "./components/Prices";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";


const WinAction = ({data}) => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Fashion Antero | Wedstrijd</title>
            </Helmet>

            <Header/>
            <Game />

            <Prices prices={data.cms.prices}/>
            <Footer/>
        </div>
    );
};

export const query = graphql`
query {
  cms {
    prices {
      description
      id
      additionalRules
      imageUrl
    }
  }
}
`

export default WinAction;
